<template>
  <div id="app">
    <component :is="layout" v-if="!loading">
      <router-view />
    </component>

    <div v-else class="appLoading">
      <img src="@/assets/logo_small.png" class="appLoadingLogo" />
      <span class="appLoadingText text-color-4">
        <Spinner />
      </span>
    </div>

    <footer class="text-color-3 w-100 text-right p-2">ProShip v 1.38.28</footer>

    <div id="toastList" class="d-flex flex-column-reverse"></div>
    <canvas id="canvas" hidden></canvas>
  </div>
</template>

<script>
const default_layout = "seller";
import { loadLanguageAsync, i18n } from "./i18n";
import Spinner from "@/components/core/Spinner";
import { applyValidators } from "@/helpers/validators";

export default {
  components: { Spinner },
  async created() {
    this.checkIfMobile();
    window.onresize = () => this.checkIfMobile();
    loadLanguageAsync("th");
  },

  methods: {
    checkIfMobile() {
      let os =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        );
      let width = window.innerWidth < 768;
      if (os || width) window.isMobile = true;
      else window.isMobile = false;
    },
  },

  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    },

    loading() {
      // return false;
      return i18n && i18n.locale == "en";
    },
  },

  watch: {
    loading() {
      applyValidators();
    },
  },
};
</script>

<style lang="scss">
.appLoading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;

  .appLoadingLogo {
    width: 100px;
    height: auto;
    margin-bottom: 1rem;
  }

  .appLoadingText {
    font-size: 3rem;
  }
}

#toastList {
  position: fixed;
  bottom: 0;
  right: 0;

  // border: 1px solid black;
  width: 300px;
  z-index: 10000;
  padding: 0.25rem;

  .toastListItem {
    background-color: #373737;
    color: white;
    border-radius: 0.25rem;
    padding: 0.25rem 0.5rem;
    margin-top: 0.5rem;

    .fixedToastBtnClose {
      cursor: pointer;
    }

    .message {
      overflow: auto;
    }
  }
}
</style>
