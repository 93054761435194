<template>
  <div>
    <div class="content-header">
      <div class="container-fluid">
        <h4 class="font-weight-light text-center text-md-left">
          <i class="fal fa-file-import mr-2"></i>
          <span class="font-weight-bold">
            {{ $t("alert.import.lead_text") }}</span>
        </h4>
      </div>
    </div>

    <div class="content container-fluid">
      <Card>
        <div slot="body">

          <div v-if="loading">
            <Spinner />
          </div>

          <p class="lead text-center"
             v-if="importLoading">
            {{ $t("alert.import.loading_message") }}
          </p>

          <div v-else>
            <div class="row">
              <div class="col">
                <Select v-if="shops"
                        id="shops"
                        :label="$t('alert.import.select_shop')"
                        :options="shops"
                        v-model="selectedShop"
                        @input="handleShopChange" />
              </div>

              <div class="col">
                <Select v-if="shippingMethods && shippingMethods.length"
                        id="shippingMethods"
                        :label="$t('shipment.edit.fields.shipping_methods')"
                        :options="shippingMethods"
                        v-model="selectedShippingMethod"
                        @input="handleShippingChange" />
              </div>
            </div>

            <div class="alert alert-warning"
                 v-if="selectedShippingMethod == 'jt'">
              เรากำลังดำเนินการอยู่ในขณะนี้ กรุณาตรวจสอบอีกครั้งในภายหลัง
            </div>

            <div v-if="selectedShop && selectedShippingMethod !== 'jt'">
              <DropZone @uploaded="importOrders"
                        :label="'ลากไฟล์มาวางตรงนี้ หรือกดเพื่อเลือกไฟล์จากเครื่องของคุณ ระบบจะนำเข้าไฟล์ตามไฟล์ตัวอย่างเท่านั้น ท่านสามารถโหลดไฟล์ตัวอย่างได้จากลิ้งค์ด้านล่าง'" />

              <p class="text-center mt-2 --text-sm">
                <a :href="sampleFileLink"
                   target="_blank"
                   rel="noopener noreferrer"
                   class="text-danger"
                   style="text-decoration: underline">
                  {{ $t("alert.import.sample_file_text") }}</a>
              </p>
            </div>

          </div>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
import DropZone from "@/components/derived/DropZone";
import Card from "@/components/core/Card";
import Button from "@/components/core/Button";
import Spinner from "@/components/core/Spinner";
import Select from "@/components/core/Select";
import moment from "moment";
import { i18n } from "@/i18n";
import { mapGetters } from "vuex";

export default {
  name: "Seller-Import-Orders",
  components: { Card, Spinner, DropZone, Button, Select },
  created() { },
  data() {
    return {
      loading: false,
      importLoading: false,
      shopsApiData: null,
      shops: null,
      shippingMethods: null,
      selectedShippingMethod: null,
      selectedShop: null,
      sampleFileLink: null,
      hasBoxSizes: null
    };
  },

  created() {
    //fetch shops
    this.fetchShops();

    // determines file sample to download
    setTimeout(() => this.fetchBoxSizes(), 1000);
  },

  methods: {
    importOrders(value) {
      var url = value[0];
      this.$store.dispatch("orders/importOrders", {
        data: { fileUrl: url, shopId: this.selectedShop, shippingMethod: this.selectedShippingMethod },
      });

      //show loading message
      this.importLoading = true;
      setTimeout(() => {
        this.importLoading = false;
      }, 2000);
    },

    fetchShops() {
      this.loading = true;
      this.shops = null;
      this.$store.dispatch("shops/fetchShops", {
        callback: (status, data) => {
          if (status) {
            this.shopsApiData = data;
            this.shops = data.map((s) => {
              return {
                name: s.details.name,
                value: s.id,
              };
            });
            this.shops.unshift({ name: "None", value: null });
            this.selectedShop = this.shops[0].id;
            this.loading = false;
          }
        },
      });
    },

    handleShopChange() {
      let selectedShop = this.shopsApiData.find(
        (s) => s.id == this.selectedShop
      );
      if (selectedShop) {
        this.shippingMethods = selectedShop.details.shippingMethods
          .filter((sm) => sm != "shopee")
          .map((sm) => {
            return { name: sm.replace("thaipost0", "eps"), value: sm };
          });
      } else this.shippingMethods = [];
      if (this.shippingMethods.length)
        this.selectedShippingMethod = this.shippingMethods[0].value;
      this.handleShippingChange();
    },

    handleShippingChange() {
      let kerrySample =
        "https://proship-dev.s3.ap-southeast-1.amazonaws.com/proship_files/ProShip-Kerry-Sample-Import.xlsx";
      let thaipost0Sample =
        "https://proship-dev.s3.ap-southeast-1.amazonaws.com/proship_files/ProShip-EPS-Sample-Import.xlsx";
      let jtSample =
        "https://proship-dev.s3.ap-southeast-1.amazonaws.com/proship_files/ProShip-JT-Sample-Import.xlsx";
      let flashSample =
        "https://proship-dev.s3.ap-southeast-1.amazonaws.com/proship_files/ProShip-Flash-Sample-Import.xlsx";
      let shopeeSample =
        "https://proship-dev.s3.ap-southeast-1.amazonaws.com/proship_files/ProShip-Shopee-Sample-Import.xlsx";
      let boxSizeSample =
        "https://proship-dev.s3.ap-southeast-1.amazonaws.com/proship_files/Import-Standard-Format-BoxSize.xlsx";

      let normalSample =
        "https://proship-dev.s3.ap-southeast-1.amazonaws.com/ProShip-ThaiPost-Sample-Import-2.xlsx";

      if (this.selectedShippingMethod == "jt") {
        this.sampleFileLink = jtSample;
      } else if (this.selectedShippingMethod == "kerry") {
        this.sampleFileLink = kerrySample;
      } else if (this.selectedShippingMethod == "flash") {
        this.sampleFileLink = flashSample;
      } else if (this.selectedShippingMethod == "thaipost0") {
        this.sampleFileLink = thaipost0Sample;
      }
      // else if (this.selectedShippingMethod == "shopee") {
      //   this.sampleFileLink = shopeeSample;
      // }
      else if (
        this.selectedShippingMethod &&
        this.selectedShippingMethod.includes("thaipost")
      ) {
        if (this.hasBoxSizes) this.sampleFileLink = boxSizeSample;
        else this.sampleFileLink = normalSample;
      }
    },

    fetchBoxSizes(callback) {
      if (this.generalSettings.thaiPostUserName) {
        this.$store.dispatch("orders/fetchBoxSizes", {
          username: this.generalSettings.thaiPostUserName,
          callback: (status, data) => {
            if (status) {
              if (data && data.length) this.hasBoxSizes = true;
            }
            if (callback) callback();
          },
        });
      }
    },
  },

  computed: {
    ...mapGetters("user", ["authData"]),
    ...mapGetters({ generalSettings: "settings/generalSettings" }),

    username() {
      if (this.authData && this.authData.user && this.authData.user.username)
        return this.authData.user.username;
      return false;
    },
  }

};
</script>

<style lang="scss" scoped></style>