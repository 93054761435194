import { http } from "@/helpers/http";

const baseURL = 'https://6vpe8vkzl6.execute-api.ap-southeast-1.amazonaws.com/dev/v1';
// const baseURL = 'http://localhost:3000/dev/v1';

export default {
  namespaced: true,

  actions: {

    generateQr({ state, commit, rootState }, payload) {
      http.post(baseURL + "/gen-qr", payload.data)
        .then(response => {
          if (payload.callback) payload.callback(true, response.data);
        })
        .catch(error => {
          if (payload.callback) payload.callback(false);
        })
    },

    fetchReport({ state, commit, rootState }, payload) {
      http.get(baseURL + "/report?from=" + payload.from + "&to=" + payload.to + "&format=" + payload.format)
        .then(response => {
          if (payload.callback) payload.callback(true, response.data);
        })
        .catch(error => {
          if (payload.callback) payload.callback(false);
        })
    },

    // FOR TEST ONLY
    // loadBalance({ state, commit, rootState }, payload) {
    //   http.post(baseURL + "/balance", payload.data)
    //     .then(response => {
    //       if (payload.callback) payload.callback(true, response.data);
    //     })
    //     .catch(error => {
    //       if (payload.callback) payload.callback(false);
    //     })
    // }
  },
};
