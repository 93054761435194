<template>
  <div class="wrapper">
    <!-- Navbar -->
    <nav class="main-header navbar navbar-expand navbar-white navbar-light">
      <!-- Left navbar links -->
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link"
             data-widget="pushmenu"
             href="#">
            <!-- <i class="fas fa-bars"></i> -->
            <div class="hamburger">
              <div class="line --top"></div>
              <div class="line --mid"></div>
              <div class="line --bottom"></div>
            </div>
          </a>
        </li>
      </ul>
      <ul v-if="alertMessage && alertMessage.length && $route.path === '/seller/orders/quick-create'"
          class="ml-auto"
          style="margin-bottom: 0">

        <div class="alert alert-danger"
             style="max-width: 600px; margin-bottom: 0; display: flex; align-items: center; gap: 1rem;">

          <img src="https://media.tenor.com/YCM_QtL_MV8AAAAi/14.gif"
               class="m-1"
               style="width: 40px; height: auto;"
               alt="">

          <span>
            {{ alertMessage }}
          </span>

        </div>
      </ul>

      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <div class="d-flex">

            <div v-if="isJtUser"
                 class="dropdown user-dropdown mr-0 mt-2 mr-md-4 align-self-center">
              <div class="handler d-flex rounded px-2 select-none"
                   style="background-color: #FFF9C4;"
                   data-toggle="dropdown">
                <img src="@/assets/images/thai-baht.png"
                     class="rounded-circle align-self-center d-none d-md-block" />
                <span class="text-color-4 align-self-center">ยอดเงินคงเหลือ J&T: ฿{{ walletBalance }}</span>
              </div>

              <div class="dropdown-menu dropdown-menu-left text-color-2 --text-sm">
                <a href="#"
                   @click="handleAddBalanceBtnClick"
                   class="dropdown-item text-color-2">
                  <i class="fal fa-plus-circle mr-1"></i>
                  เติมเงิน
                </a>
              </div>
            </div>

            <button @click="toggleColorMode"
                    type="button"
                    class="nav-link btn mr-2 mr-md-4 align-self-center">
              <span>🌗</span>
            </button>

            <div class="dropdown user-dropdown mr-0 mt-2 mr-md-4 align-self-center">
              <div class="handler d-flex"
                   data-toggle="dropdown">
                <img src="@/assets/images/admin_panel/seller.png"
                     class="rounded-circle align-self-center d-none d-md-block" />
                <span class="text-color-4 align-self-center">{{
                  username
                }}</span>
                <i class="fal fa-chevron-down text-color-4 align-self-center"></i>
              </div>

              <div class="dropdown-menu dropdown-menu-left text-color-2 --text-sm">
                <router-link v-if="isSeller"
                             :to="{ name: 'Seller-Settings' }"
                             class="dropdown-item text-color-2">
                  <i class="fal fa-cog mr-1"></i>
                  {{ $t("general.settings") }}
                </router-link>
                <a class="dropdown-item text-color-2"
                   href="#"
                   @click="logout()">
                  <i class="fal fa-sign-out mr-1"></i>
                  {{ $t("general.logout") }}
                </a>
              </div>
            </div>
          </div>
        </li>

        <li class="nav-item"></li>
      </ul>
    </nav>
    <!-- /.navbar -->

    <!-- Main Sidebar Container -->
    <aside class="main-sidebar sidebar-light elevation-2">
      <!-- Brand Logo -->
      <router-link to
                   class="brand-link">
        <div class="d-flex">
          <img id="dashboard-logo"
               src="@/assets/images/shared/proship-logo-icon.png"
               alt="AdminLTE Logo"
               class="brand-image align-self-center" />
          <span class="brand-text text-color-4 text-uppercase align-self-center">
            Proship
          </span>
        </div>
      </router-link>

      <!-- Sidebar -->
      <div class="sidebar d-flex flex-column">
        <!-- Sidebar Menu -->
        <nav class="mt-4 mr-n4 flex-grow-1">
          <ul class="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false">
            <li class="nav-item"
                v-for="(menuItem, index) in menus"
                :key="index">
              <router-link :to="{ name: menuItem.pathName[0] }"
                           class="nav-link py-2"
                           :class="{ active: markActive(menuItem) }">
                <i class="nav-icon"
                   :class="menuItem.icon"></i>
                <p>{{ menuItem.title }}</p>
              </router-link>
            </li>
          </ul>

          <div class="d-flex mt-3 ml-3">
            <a href="https://www.facebook.com/proship.co.th"
               target="_blank"
               rel="noopener noreferrer">
              ติดต่อเรา
            </a>
            <!-- <img src="@/assets/images/jt.jpg" class="img-fluid" /> -->
          </div>
        </nav>

        <!-- <div class="strawpoll-embed" id="strawpoll_BJnX87XVdnv"
          style="height: 516px; max-width: 500px; width: 100%; margin: 0 auto; display: flex; flex-direction: column;">
          <iframe title="StrawPoll Embed" id="strawpoll_iframe_BJnX87XVdnv"
            src="https://strawpoll.com/embed/polls/BJnX87XVdnv"
            style="position: static; visibility: visible; display: block; width: 100%; flex-grow: 1; font-size: 12px;"
            frameborder="0" allowfullscreen allowtransparency>Loading...</iframe>
        </div> -->

        <!-- <div class="strawpoll-embed" id="strawpoll_BJnX87XVdnv"
          style="height: 480px; max-width: 640px; width: 100%; margin: 0 auto; display: flex; flex-direction: column;">
          <iframe title="StrawPoll Embed" id="strawpoll_iframe_BJnX87XVdnv"
            src="https://strawpoll.com/embed/polls/BJnX87XVdnv"
            style="position: static; visibility: visible; display: block; width: 100%; flex-grow: 1;" frameborder="0"
            allowfullscreen allowtransparency>Loading...</iframe>
        </div> -->

        <!-- <div class="strawpoll-embed" id="strawpoll_61gDmbQkVZw"
          style="height: 480px; max-width: 640px; width: 100%; margin: 0 auto; display: flex; flex-direction: column;">
          <iframe title="StrawPoll Embed" id="strawpoll_iframe_61gDmbQkVZw"
            src="https://strawpoll.com/embed/polls/61gDmbQkVZw"
            style="position: static; visibility: visible; display: block; width: 100%; flex-grow: 1;" frameborder="0"
            allowfullscreen allowtransparency>Loading...</iframe>
        </div> -->

        <div>
          <img src="@/assets/images/Proship-notice.png" />
        </div>

        <div class="alert alert-default d-flex"
             v-if="fbPageIdsChecker.loading">
          <i class="fas fa-sync spin align-self-center mr-2"></i>
        </div>

        <div v-else
             class="cursor-pointer">
          <div class="alert alert-success d-flex"
               v-if="fbPageIdsChecker.status">
            <i class="fas fa-check-circle align-self-center mr-2"></i>

            <span class="text-xs align-self-center">
              ระบบทำงานปกติ
            </span>
          </div>

          <div class="alert alert-danger d-flex"
               v-else-if="fbPageIdsChecker.errorShop">
            <i class="fas fa-times-circle align-self-center mr-2"></i>

            <p class="text-xs align-self-center">
              Fb page-id:
              <router-link :to="{ name: 'Seller-Settings-Shops', query: { shopId: fbPageIdsChecker.errorShop.id } }"
                           class="text-info"> {{ fbPageIdsChecker.errorShop.name }} <i class="fa fa-link"
                   aria-hidden="true"></i> </router-link>
            </p>
          </div>
        </div>

        <button class="btn btn-outline-primary btn-sm"
                v-if="isFlashUser"
                data-toggle="modal"
                data-target="#flash-notify-modal">
          Pickup / เรียกรถเข้ารับ เฉพาะ Flash
        </button>

        <!-- /.sidebar-menu -->
      </div>
      <!-- /.sidebar -->
    </aside>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <slot></slot>
    </div>
    <!-- /.content-wrapper -->

    <FixedToast :title="socketMsg.topic || 'Shipment Import'"
                :message="socketMsg.msg ? socketMsg.msg : socketMsg"
                v-if="socketMsg"
                @close="() => (socketMsg = null)" />

    <Modal id="flash-notify-modal"
           custom_dialog_class="modal-dialog-centered">
      <template slot="body">
        <div>
          <p class="lead text-center mb-4">Flash: Pickup / เรียกรถเข้ารับ</p>

          <ValidationObserver v-slot="{ invalid }">
            <div class="row">
              <div class="col">
                <Select v-if="flashShops"
                        id="shops"
                        :label="$t('alert.import.select_shop')"
                        :options="flashShops"
                        v-model="selectedFlashShop" />
              </div>

              <div class="col"></div>
            </div>

            <div class="card card-body bg-light --text-vsm"
                 v-if="flashTicketInfo"
                 v-html="Object.values(flashTicketInfo).join('<br>')"></div>

            <div class="d-flex justify-content-center mt-4">
              <Button id="flash-notify-dialog"
                      variant="primary"
                      size="sm"
                      @click="flashNotify()"
                      :loading="flashNotifyLoading"
                      :disabled="flashNotifyLoading || invalid">{{ $t("general.submit")
                }}</Button>
            </div>
          </ValidationObserver>
        </div>
      </template>
    </Modal>

    <Modal id="wallet-load-modal"
           custom_dialog_class="modal-dialog-centered">
      <template slot="body">
        <div class="my-5">
          <h3 class="lead text-center mb-5"
              style="font-size: 2rem">เติมเงิน</h3>


          <form @submit.prevent="handleWalletLoadSubmit"
                novalidate>

            <div class="row text-center mb-5">
              <div class="col">
                <select id="balance"
                        class="form-control"
                        style="font-size: 2rem"
                        @change="walletLoadQrBase64 = null"
                        v-model="walletLoadForm.balance">
                  <option :value="null"
                          disabled>เลือกจำนวนเงิน</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="500">500</option>
                  <option value="1000">1000</option>
                </select>

              </div>
            </div>

            <div class="text-center">
              <Button type="submit"
                      :disabled="walletLoadFormLoading || !walletLoadForm.balance || (walletLoadQrBase64 && walletLoadQrBase64.length)"
                      :loading="walletLoadFormLoading"
                      class="btn btn-primary"
                      size="lg">สร้าง QR</Button>
            </div>
          </form>

          <div v-if="walletLoadQrBase64"
               class="mt-5">

            <h6 class="text-color-4 text-lg mb-4 text-center"> สแกนและชำระเงินผ่านพร้อมเพย์ หลังจากชำระเงินสำเร็จ
              ยอดเงินจะถูกเพิ่มให้อัตโนมัติ</h6>

            <div class="text-center">
              <img :src="walletLoadQrBase64"
                   style="width: 254px; height: 254px" />
            </div>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/core/Modal";
import Toggle from "@/components/core/Toggle";
import Button from "@/components/core/Button";
import Stepper from "@/components/core/Stepper";
import TextBox from "@/components/core/TextBox";
import NumberField from "@/components/core/NumberField";
import DatePicker from "@/components/core/DatePicker";
import DropZone from "@/components/derived/DropZone";
import FixedToast from "@/components/core/FixedToast";
import { mapGetters } from "vuex";
import { FILTERS } from "@/helpers/filters";
import { i18n } from "@/i18n";
import Select from "@/components/core/Select";
import { initWs, handleMessage } from "@/helpers/ws";
import Hotjar from "@hotjar/browser";
import axios from "axios";
import { Toast } from "@/helpers/toastr";

export default {
  name: "Seller",
  components: {
    Modal,
    TextBox,
    DatePicker,
    NumberField,
    DropZone,
    Stepper,
    Button,
    Toggle,
    FixedToast,
    Select,
  },
  data() {
    return {
      permissionOptions: [
        { name: "Manage only products", value: 0 },
        { name: "Manage only orders", value: 1 },
        { name: "Manage both orders & products", value: 2 },
      ],
      buttonLoading: false,
      flashNotifyLoading: false,
      currentRoute: null,
      addBalanceForm: {
        date: null,
        time: null,
        amount: null,
        taxInvoice: null,
        slips: null,
      },
      menus: null,
      totalBalance: null,
      socketMsg: null,
      selectedFlashShop: null,
      selectedFlashWarehouse: null,
      flashTicketInfo: null,
      fbPageIdsChecker: {
        loading: false,
        status: false,
        pageIds: {},
        errorShop: null,
        total: 0,
        lastUpdatedAt: null
      },
      alertMessage: "",

      walletBalance: 0,
      walletLoadForm: {
        balance: null,
      },
      walletLoadQrBase64: null,
      walletLoadFormLoading: false,
    };
  },

  created() {
    this.initNavbarMenu();
    this.setCurrentRoute(this.$route);

    // live product & orders permission change detection
    this.$store.subscribe((mutation, state) => {
      if (mutation.type.startsWith("settings")) this.initNavbarMenu();
    });
    this.$store.dispatch("settings/fetchGeneralSettings");
    this.$store.dispatch("settings/fetchShippingMethods");
    // this.$store.dispatch("settings/fetchFlashWarehouses");
    this.$store.dispatch("shops/fetchShops", {
      query: "responseSize=all",
      callback: async (status, data) => {
        if (status) await this.runFbPageIdsCheck(data);
      }
    });

    // socket ec2
    // let socketUrl = "http://localhost:5000/";
    let socketUrl = "https://jobs.proship.co.th/";
    var socket = io(socketUrl, {
      query: "username=" + this.username,
    });
    socket.on("message", (message) => {
      this.socketMsg = message;
    });

    // websocket-lamda
    let ws = initWs();
    ws.addEventListener("message", (event) => handleMessage(event));

    if (this.$route.path === "/seller/orders/quick-create") {
      // alert message
      axios.get("https://proship-dev.s3.ap-southeast-1.amazonaws.com/proship_files/alert_message.txt", {
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
        }
      }).then(({ data }) => {
        this.alertMessage = data;
      }).catch(e => {
        console.log(e)
      })
    }
  },

  mounted() {
    if (
      this.authData.user.id ==
      "user-7f1087d0-8226-11eb-8d24-511bd6b47eea1615438741709"
    ) {
      const siteId = 3201985;
      const hotjarVersion = 6;

      Hotjar.init(siteId, hotjarVersion);

      // // Initializing with `debug` option:
      // Hotjar.init(siteId, hotjarVersion, {
      //   debug: true,
      // });
    }

    if (localStorage.getItem("darkmode") && localStorage.getItem("darkmode") === "true") {
      this.toggleColorMode()
    }

    this.fetchBalance();

    document.addEventListener("wallet-load", (data) => {

      // hide nad reset form
      $("#wallet-load-modal").modal("hide");
      this.walletLoadForm = { balance: null };
      this.walletLoadQrBase64 = null;
      this.fetchBalance();

      if (data && data.detail && data.detail.amount)
        Toast(`฿${data.detail.amount} ถูกเติมเข้ากระเป๋าเงินแล้ว.`);
    });

  },

  destroyed() {
    clearInterval(this.timer);
  },

  methods: {

    handleAddBalanceBtnClick() {
      this.walletLoadForm = { balance: null };
      this.walletLoadQrBase64 = null;
      $("#wallet-load-modal").modal("show");
    },

    handleWalletLoadSubmit() {
      this.walletLoadFormLoading = true;
      // 

      this.$store.dispatch("wallet/generateQr", {
        data: this.walletLoadForm,
        callback: (status, data) => {
          this.walletLoadFormLoading = false;
          if (status) {
            if (data.data && data.data.image) this.walletLoadQrBase64 = data.data.image;
          }
          this.walletLoadFormLoading = false;
          // $("#wallet-load-modal").modal("hide");
          // this.walletLoadForm.balance = 0;
        }
      });
    },

    toggleColorMode() {
      this.colorMode = !this.colorMode;
      if (document.body.classList.contains("--dark")) document.body.classList.remove("--dark")
      else document.body.classList.add("--dark")

      localStorage.setItem("darkmode", this.colorMode);
    },

    async runFbPageIdsCheck(shops, force = false) {
      // run on 6hrs interval
      let cachedValue;
      try {
        cachedValue = JSON.parse(localStorage.getItem("fbPageIdsChecker"));
      } catch (e) {
        console.log(e)
      }

      if (cachedValue) this.fbPageIdsChecker = cachedValue
      if (this.fbPageIdsChecker.lastUpdatedAt) {
        const millis = Date.now() - this.fbPageIdsChecker.lastUpdatedAt;
        const mins = millis / (1000 * 60);
        const hours = millis / (1000 * 60 * 60);
        // console.log("Mins: ", mins, "Hours: ", hours);

        if (mins <= 60) {
          return;
        }
      }

      this.fbPageIdsChecker.loading = true;
      this.fbPageIdsChecker.total = shops.length;
      // debugger;
      for (const shop of shops) {
        let hasError = false;
        if (shop.details.fbPageId) {
          const { data } = await axios.get(`https://urr58tp7nl.execute-api.ap-southeast-1.amazonaws.com/dev/check-fb-page?pageId=${shop.details.fbPageId}`)
          hasError = data.pageIsConnected === false
        } else hasError = true;

        if (hasError === true) {
          this.fbPageIdsChecker = { ...this.fbPageIdsChecker, status: false, errorShop: { id: shop.id, name: shop.details.name } }
        } else {
          this.fbPageIdsChecker.status = true;
        }
      }
      this.fbPageIdsChecker.loading = false;
      this.fbPageIdsChecker.lastUpdatedAt = Date.now();
      localStorage.setItem("fbPageIdsChecker", JSON.stringify(this.fbPageIdsChecker));
    },

    flashNotify() {
      this.flashNotifyLoading = true;
      this.$store.dispatch("orders/flashNotify", {
        data: {
          shopId: this.selectedFlashShop,
        },
        callback: (status, data) => {
          this.flashNotifyLoading = false;
          if (status) {
            this.flashTicketInfo = data.ticketInfo;
          }
        },
      });
    },

    markActive(menuItem) {
      let includes = menuItem.pathName.includes(this.currentRoute);
      // if (includes) debugger;
      return includes;
    },

    initNavbarMenu() {
      // pathname can contain multiple values
      // routed to the 1st one only
      this.menus = [
        {
          title: i18n.t("dashboard.dashboard_label"),
          icon: "fal fa-tachometer-fastest",
          pathName: ["Seller-Dashboard", "Seller-Home", "Dash-Intro"],
        },
      ];
      // Product Management is conditional
      if (this.generalSettings && this.generalSettings.productMgt) {
        this.menus.push({
          title: i18n.t("products.products_label"),
          icon: "fal fa-box-full",
          pathName: [
            "Seller-Product-List",
            "Seller-Product-Create",
            "Seller-Product-Edit",
            "Seller-Product-Detail",
          ],
        });
      }
      this.menus.push(
        {
          title: "ลูกค้า",
          icon: "fal fa-users",
          pathName: ["Seller-Customer-List"],
        },

        // {
        //   title: "เปิดบิลขาย",
        //   icon: "fal fa-box-check",
        //   pathName: ["Seller-PreShipment-List"],
        // },

        {
          title: i18n.t("shipment.label"),
          icon: "fal fa-truck-loading",
          pathName: [
            "Seller-Order-List",
            "Seller-Order-Detail",
            "Seller-Order-Create",
            "Seller-Order-Edit",
          ],
        },

        {
          title: i18n.t("general.import"),
          icon: "fal fa-cloud-download",
          pathName: [
            "Seller-Import-Orders"
          ],
        }
      );

      if (this.authData && this.authData.user.role === "sales-agent") {
        this.menus.push({
          title: "ค้นหา 🔍",
          icon: "fal fa-truck-loading",
          pathName: ["Seller-Order-Super-SalesAgent-List"],
        });
      }

      if (this.authData && this.authData.user.role === "seller") {
        this.menus.push(
          {
            title: "การจัดส่งพนักงาน",
            icon: "fal fa-truck-loading",
            pathName: ["Seller-Order-SalesAgent-List-V2"],
          },

          {
            title: "ค้นหา",
            icon: "fal fa-file-search",
            pathName: ["Search-Orders"],
          }
        );
      }

      this.menus.push({
        title: i18n.t("reports.label"),
        icon: "fal fa-chart-line",
        pathName: ["Reports-Shipments"],
      });

      // this.menus.push({
      //   title: "Product Reports",
      //   icon: "fal fa-chart-line",
      //   pathName: ["Reports-Products"],
      // });

      if (this.authData && this.authData.user.role === "seller")
        this.menus.push({
          title: i18n.t("general.settings"),
          icon: "fal fa-cogs",
          pathName: [
            "Seller-Settings",
            "Seller-Settings-Shops",
            "Seller-Settings-Payment",
            "Seller-Settings-Shipping",
            "Seller-Settings-Sales-Agent",
            "Seller-Settings-Account",
          ],
        });
    },

    addBalanceStyle() {
      if (this.totalBalance < 1000)
        return `background-color: rgba(246, 116, 116, 0.1); color: #f67474;`;
      else return `background-color: rgba(48, 196, 76, 0.1); color: #30c44c;`;
    },

    logout() {
      this.$store.dispatch("user/logout", {
        callback: () => {
          clearInterval(this.timer);
          this.$router.push({ name: "Login" });
        },
      });
    },

    setCurrentRoute(route) {
      this.currentRoute = route.name;
    },

    initForm() {
      if (!this.isSeller) return;

      this.$refs.balanceForm.reset();
      let date = new Date();
      this.addBalanceForm.date = FILTERS.formatDate(date);
      this.addBalanceForm.time = date.toLocaleTimeString();
    },

    fetchBalance() {
      this.$store.dispatch("settings/getBalance", {
        callback: (status, data) => {
          if (data.balance) {
            this.walletBalance = data.balance;
          } else this.walletBalance = 0;
        },
      });
    },
  },

  computed: {
    ...mapGetters({
      generalSettings: "settings/generalSettings",
      shippingMethods: "settings/shippingMethods",
      flashWarehouses: "settings/flashWarehouses",
      shops: "shops/shops",
    }),
    ...mapGetters("user", ["authData"]),

    username() {
      if (this.authData && this.authData.user && this.authData.user.username)
        return this.authData.user.username;
      return "User";
    },

    isSeller() {
      if (this.authData && this.authData.user && this.authData.user.role)
        return this.authData.user.role === "seller";
      return false;
    },

    isThaipostUser() {
      return this.generalSettings && this.generalSettings.thaiPostUserName;
    },

    isFlashUser() {
      return this.shippingMethods && this.shippingMethods.includes("flash");
    },

    isJtUser() {
      return this.shippingMethods && this.shippingMethods.includes("jt");
    },

    flashShops() {
      if (this.shops) {
        return this.shops
          .filter((s) => s.details.shippingMethods.includes("flash"))
          .map((s) => {
            return {
              value: s.id,
              name: s.details.name,
            };
          });
      }
    },
  },

  watch: {
    $route(to, from) {
      this.setCurrentRoute(to);
    },
  },
};
</script>

<style lang="scss">
.content-wrapper {
  font-size: 12px;
}

.nav-icon {
  margin-right: 1.5rem !important;
}

// ------------------Override styles-----------

.hamburger {
  $gap: 4.89px;
  margin-top: 6px;

  .line {
    height: 2px;
    border-radius: 5px;
    background-color: map-get($text-colors, 4);

    &.--top {
      width: 12px;
    }

    &.--mid {
      margin-top: $gap;
      width: 24px;
    }

    &.--bottom {
      margin-top: $gap;
      width: 17px;
    }
  }
}

.main-header {
  border-bottom: none;
}

.main-sidebar {
  background-color: white;

  .brand-link {
    border-bottom: none;
    height: auto !important;

    .brand-image {
      margin-left: 0.4rem;
      max-height: 48px;

      @include for-desktops-and-up {
        max-height: 59px;
      }

      transition: all 0.2s ease-in-out;
    }

    .brand-text {
      font-size: 1rem;
      line-height: 16px;
    }
  }

  .sidebar {
    overflow-x: hidden;
    overflow-y: auto;

    .nav-link {
      margin-top: 8px;
      padding-top: 0.6rem;
      padding-bottom: 0.6rem;
      border-radius: 10px;
      color: map-get($text-colors, 5);

      &.router-link-active,
      &.active {
        background-color: rgba($brand-blue-mid, 0.1);
        color: $brand-blue-mid;
      }

      @include for-tablets-only {
        padding-top: 0.7rem;
        padding-bottom: 0.7rem;
      }

      @include for-laptops-only {
        padding-top: 0.8rem;
        padding-bottom: 0.8em;
      }

      @include for-desktops-and-up {
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }
  }
}

.content-header {
  padding-top: 20px;

  @include for-larger-than-phone {
    padding-top: 40px;
  }
}

.content-wrapper {
  background-color: $dashboard-background;
}

#btn-add-balance {
  background-color: rgba($brand-green-mid, 0.1);
  color: $brand-green-mid;
}

.user-dropdown {
  cursor: pointer;

  img {
    width: 40px;
    height: 40px;
    margin-right: 8px;
  }

  span {
    margin-right: 8px;
  }

  .dropdown-menu {
    left: 50%;
    transform: translate(-50%);
  }
}
</style>