<template>
  <div class="settings-page">
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2 ml-4">
          <div class="col-sm-6">
            <h4 class="font-weight-light">
              {{ $t("general.all") }}
              <span class="font-weight-bold">{{ $t("general.settings") }}</span>
            </h4>
          </div>
          <div class="col-sm-6 d-none d-md-block">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"
                  v-for="(item, index) in breadcrumbLinks"
                  :key="index">
                <router-link :to="{ name: item.pathName }"
                             :class="{ 'breadcrumb-item': true, active: item.isActive }">{{ item.title }}</router-link>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="container-fluid">
        <div class="d-flex">
          <ul class="settings-menu d-none d-md-block">
            <li v-for="(menuItem, index) in settingsMenu"
                :key="index">
              <p class="settings-menu-item"
                 :class="{
                  '--active': menuItem.pathNames.includes($route.name),
                }">
                <router-link :to="{ name: menuItem.pathNames[0] }">
                  <i class="fal mr-1"
                     :class="'fa-' + menuItem.icon"></i>
                  {{ menuItem.label }}
                </router-link>
              </p>
            </li>
          </ul>

          <div class="settings-content flex-grow-1 mt-md-4">
            <div class="tabs container d-block d-md-none"
                 id="tabs">
              <Tabs ref="tabs"
                    :tabs="settingsMenu"
                    custom_class="is-primary"
                    :active_tab_index="activeTabIndex"
                    @tabSelected="handleTabSelected" />
            </div>
            <Card>
              <div slot="body">
                <section class="content">
                  <div class="container-fluid">
                    <router-view />
                  </div>
                </section>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/components/core/Card";
import Tabs from "@/components/core/Tabs";
import { i18n } from "@/i18n";
import { mapGetters } from "vuex";

export default {
  name: "Settings",
  components: { Card, Tabs },
  data() {
    return {
      breadcrumbLinks: [
        {
          title: i18n.t("dashboard.dashboard_label"),
          isActive: true,
          pathName: "Seller-Dashboard",
        },
        {
          title: i18n.t("general.settings"),
          isActive: false,
          pathName: "Seller-Settings",
        },
      ],

      settingsMenu: [
        {
          label: i18n.t("settings.general.label"),
          icon: "sliders-h",
          pathNames: ["Seller-Settings-General", "Seller-Settings"],
        },

        {
          label: i18n.t("general.shop"),
          icon: "store",
          pathNames: ["Seller-Settings-Shops"],
        },
        {
          label: i18n.t("settings.account.label"),
          icon: "user-circle",
          pathNames: ["Seller-Settings-Account"],
        },
        {
          label: i18n.t("settings.sales_agents.label"),
          icon: "user-hard-hat",
          pathNames: ["Seller-Settings-Sales-Agents"],
        },
        {
          label: i18n.t("settings.api.label"),
          icon: "globe",
          pathNames: ["Seller-Settings-Api"],
        },
        {
          label: i18n.t("settings.shipping_method_settings"),
          icon: "shipping-fast",
          pathNames: ["Seller-Settings-Shipping"],
        },

        {
          label: "ระบบรับออเดอร์",
          icon: "shopping-cart",
          pathNames: ["Seller-Settings-Checkout"],
        }
      ],
      activeTabIndex: 0,

      driver: null,
      showInventory: false,
    };
  },

  created() {
    this.activeTabIndex = this.settingsMenu.findIndex((menuItem) => {
      return menuItem.pathNames.includes(this.$route.name);
    });
    // console.log(this.$route.name);
  },

  methods: {
    handleTabSelected(index, callback) {
      this.$router.push({ name: this.settingsMenu[index].pathNames[0] }, () => {
        if (callback) callback();
      });
    },

    // initGuide() {
    //   this.driver = new Driver({
    //     className: "driver-bubble",
    //     onReset: Element => {
    //       let elemId = Element.options.element;

    //       // Payment page
    //       if (elemId === "#btn-add-new-shop") {
    //         this.$refs.tabs.setActiveTab(1);
    //         setTimeout(() => {
    //           this.driver.defineSteps([
    //             {
    //               element: "#btn-setup-payment",
    //               popover: {
    //                 title: "Add payment details",
    //                 description:
    //                   "Fill up the form to provide your bank details to be used for shipping",
    //                 position: "top",
    //                 closeBtnText: "View Shipping"
    //               }
    //             }
    //           ]);
    //           this.driver.start();
    //         }, 500);
    //       }

    //       // Shipping page
    //       if (elemId === "#btn-setup-payment") {
    //         this.$refs.tabs.setActiveTab(2);

    //         setTimeout(() => {
    //           this.driver.defineSteps([
    //             {
    //               element: "#shipping-input",
    //               popover: {
    //                 title: "Shipping methods",
    //                 description: "Choose the shipping methods yu want to use",
    //                 position: "top",
    //                 closeBtnText: "Manage Account"
    //               }
    //             }
    //           ]);
    //           this.driver.start();
    //         }, 500);
    //       }

    //       // Account page
    //       if (elemId === "#shipping-input") {
    //         this.$refs.tabs.setActiveTab(3);

    //         setTimeout(() => {
    //           this.driver.defineSteps([
    //             {
    //               element: "#account-form",
    //               popover: {
    //                 title: "Account form",
    //                 description: "Change your account settings from here",
    //                 position: "top",
    //                 closeBtnText: "Add Sales Agents"
    //               }
    //             }
    //           ]);
    //           this.driver.start();
    //         }, 500);
    //       }

    //       // Sales Agents Page
    //       if (elemId === "#account-form") {
    //         this.$refs.tabs.setActiveTab(4);

    //         setTimeout(() => {
    //           this.driver.defineSteps([
    //             {
    //               element: "#sales-agents-table",
    //               popover: {
    //                 title: "Sales agents list",
    //                 description: "Here you can see a test Sales Agent",
    //                 position: "top"
    //               }
    //             },

    //             {
    //               element: "#btn-add-sales-agent",
    //               popover: {
    //                 title: "Add new Agent",
    //                 description: "Here you can add new agent",
    //                 position: "top",
    //                 closeBtnText: "Finish Tour"
    //               }
    //             }
    //           ]);
    //           this.driver.start();
    //         }, 500);
    //       }
    //     }
    //   });
    //   this.driver.defineSteps([
    //     {
    //       element: "#tabs",
    //       popover: {
    //         title: "Site settings",
    //         description: "Manage all of your settings from here",
    //         position: "bottom"
    //       }
    //     },
    //     {
    //       element: "#shop-details-0",
    //       popover: {
    //         title: "🛍️ Congrats",
    //         description: "This is the shop you just added",
    //         position: "bottom"
    //       }
    //     },
    //     {
    //       element: "#btn-add-new-shop",
    //       popover: {
    //         title: "Add new shops",
    //         description: "Just like you did previously",
    //         position: "top",
    //         closeBtnText: "Manage Payments",
    //         nextBtnText: null,
    //         prevBtnText: null
    //       }
    //     }
    //   ]);
    //   // Start the introduction
    //   this.driver.start();
    // }
  },

  computed: {
    ...mapGetters({
      shippingMethods: "settings/shippingMethods"
    }),

    isJtUser() {
      return this.shippingMethods && this.shippingMethods.includes("jt");
    },
  }
};
</script>

<style lang="scss" scoped>
//   .driver-next-btn {
//     background-color: map-get($variants, "success");
//     color: white;
//   }
// }
// .settings-page{
// margin-left: toRem(20px);
// }
.settings-menu {
  margin-top: 1.5rem;
  margin-right: toRem(60px);
  min-width: toRem(190px);

  li {
    list-style-type: none;

    .settings-menu-item {
      cursor: pointer;
      margin-bottom: toRem(40px);

      a {
        color: map-get($text-colors, 5);
        text-decoration: none;
      }

      &.--active {
        a {
          color: $brand-blue-mid;
        }
      }
    }
  }
}
</style>